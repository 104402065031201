<template>
	<main>
		<HeaderTab :title="$t('global.titre_tiers_archive')" :return_action="'navigationBack'"/>
		<!-- integrer le header et le swiper -->
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="table"
								id_table="tiers_archive"
								:busy.sync="table_busy"
								primaryKey="tiers_id"
								:hrefsRoutes="config_table_hrefs"
								:hide_if_empty="true"
								:rawColumns="rawColumnNames"
								:transformer="['TierTransformer', 'WithPhoneAndClientAccount']"
								:base-filters="filters"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import Vue from 'vue'
import Navigation from "@/mixins/Navigation.js"
import Tiers from "@/mixins/Tiers.js"

export default {
	name: "TiersArchivedListe",
	mixins: [Navigation, Tiers],
	data () {
		return {
			/* Configuration du tableau : */
			table_busy: true,
			tiers: [],
			config_table_hrefs: {
				'tiers_rs': {
					routeUniqueName: 'tiersTableauBord',
					routeName: 'tiersTableauBord',
					params: {
						tiers_id: 'tiers_id'
					}
				}
			},
			rawColumnNames: ['phones_number'],
			events_tab: {
				'TableAction::goToUnarchiveTiers': (tiers_ids) => {
					this.unarchive(tiers_ids)
				}
			},
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
		},
		unarchive(tiers_ids) {
			this.unarchiveTiers(tiers_ids)
			.then(() => {
				this.$refs.table.refreshTable()
			})
		}
	},
	computed: {
		filters() {
			let filters = {
				tiers: {
					column: 'tiers_archive',
					operator: 'isEqualTo',
					value: 1
				}
			}

			return filters
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable')
	}
}
</script>
